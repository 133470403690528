import {deepClone} from "../components/dynamicForm/utils/utils";
import {_routes} from '@/router/index.js'
import router from "../router";
export const initRoute = (sysModule)=>{
    let routerList = deepClone(_routes)
    const _init = (_target,_list,tag)=>{
        _target.forEach(ele=>{
            let index = _list.findIndex(i=>i.meta&&i.meta.id==ele.id)
            if(!~index) return ;
            _list[index].meta.isShow = true;
            _list[index].meta.title = ele.name;
            _list[index].name= ele.name;
            _list[index].meta.code= ele.code;
            if(ele.child&&ele.child.length){
                _init(ele.child,_list[index].children,true)
            }
            if(_list[index].children && _list[index].children.length) {
                const _filterChild = _list[index].children.filter(e => e.meta && e.meta.isShow)
                _filterChild && _filterChild .length && (_list[index].redirect = _filterChild[0].path)
            }
            if(!tag){
                router.addRoute(_list[index])
            }
        })
    }
    if (sysModule !== null) {
        _init(sysModule.child.filter(e => e.id == 2)[0].child,routerList)
    }
    return routerList.sort((a,b)=>{
        return a.meta.code - b.meta.code
    })
}
