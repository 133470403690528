import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from './services/apiList'
import './style/reset.scss';
import './style/main.scss';
import './style/changeElementUi.scss';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css';
import reconava from "./utils/tool";
import map from "./utils/map";
import filters from "./utils/filters.js";
import throttle from "./directive/throttle.js"
import { formValidator } from "./utils/validator";
Vue.use(Viewer,{defaultOptions:{
  zIndex: 9999, // 这里设置层级高度
}})
Vue.use(throttle);
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.use(ElementUI,{size: 'mini'})
Vue.prototype.$api = api;
Vue.prototype.$reconava = reconava;
Vue.prototype.$map = map;
Vue.prototype.$formValidator = formValidator;

let loginEnv = 'test'
if(location.hostname.includes("imallprotal.crcapm.com.cn")){
  loginEnv = 'hls';
}
if(location.hostname.includes("whjoycity.cn")){
  loginEnv = 'wuhan';
}
Vue.prototype.$loginEnv = loginEnv;
// import Router from 'vue-router'
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
// return routerPush.call(this, location).catch(error=> error)
// }
Object.keys(filters).forEach((name) => {
  Vue.filter(name, filters[name]);
});


new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this;
  }
}).$mount('#app')
