import axios from 'axios';
import router from '../router'
import {
  setCookie
} from "@/utils/utils";
import {Loading, Message} from "element-ui";

function getPrefixUrl(){
  let url = '';
  switch (document.domain) {
    case '39.99.134.179':
      url = 'https://dkpt.frontend.net/aios/api'
      break;
    case '127.0.0.1':
      url = 'http://127.0.0.1:18085/aios/api'
      break;
    case 'dkpt.demo.net':
      url = 'http://dkpt.demo.net:30013/dkpt/aios/api'
      break;
    default:
      url = "/aios/api"
  }
  return url
}

function getToken(){
  let item = document.cookie.split(';')
  const obj = {};
  for (const i of item) {
    obj[i.split('=')[0]] = i.split('=')[1]; // 对数组每项用=分解开，=前为对象属性名，=后为属性值
  }
  let result = '';
  Object.keys(obj).forEach((item) => {
    if (item.includes('TOKEN')) {
      result = obj[item]
    }
  })
  return result;
}


const baseURL = process.env.NODE_ENV === 'development' ? '/aios/api' : getPrefixUrl()
let loading;
const instance = axios.create({
  baseURL,
  // responseType: 'blob',
  withCredentials: true,
  // 如果请求话费了超过 `timeout` 的时间，请求将被中断
  //  timeout: 1000,
});
let requestingMap = {};
instance.interceptors.request.use(function (config) {
  // 全局拦截重复的请求
  let url = config.url.split('/').filter(e => e).join('/')
  if(requestingMap[url]){
    // throw new axios.Cancel(`${url}接口请求中，请勿重复发起！`);
  }else{
    requestingMap[url] = true;
  }
  let token = document.cookie.includes('DK_TOKEN')
  if (token) {
    config.headers.token = getToken(); //将token放到请求头发送给服务器
    return config;
  } else {
    return config;
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  loading && loading.close()
  let url = response.config.url.slice(response.config.baseURL.length + 1);
  requestingMap[url] = false;
  if (response.config.responseType === 'arraybuffer') {
    return response.data
  }
  if ((response.config.url.includes('/export')||response.config.url.includes('/download')) && response.status === 200 || response.status === 304) {
    return response
  } else if (response.status === 200 || response.status === 304) {
    if (response.data.code === 81107) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setCookie("DK_TOKEN", "", -1);
      window.localStorage.clear()
      if (userInfo && userInfo.type === 1) {
        router.push("/login");
      } else {
        window.top.postMessage('reloadPortal', '*')
      }
      return;
    }else if(response.data.code === 200|| response.data.code === 450){
      // 450状态码是 BPM 流程返回的 通常表示数据，需要直接返回
      return response.data
    }else if(response.data.code!==201){
      Message.error(response.data.msg)
      return Promise.reject(response.data);
    }
  }else{
    return Promise.reject('error')
  }
}, // 处理后端返回数据格式
  error => {
    return Promise.reject(error)
  });


const request = (method, url, config, type) => {
  if(config && config.hideLoading){
    loading && loading.close()
    delete config.hideLoading
  }else{
    loading =  Loading.service({
      lock:true,
      text:'请稍等...',
      background:'rgba(255,255,255,0.2)',
      customClass:'api-loading-wrap'
    })
  }

  if (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' || method.toUpperCase() === 'PATCH') {
    let obj = {}
    if (type) {
      obj.params = config
      obj.responseType = type
    } else {
      obj = config
    }
    return instance[method.toLocaleLowerCase()](url, obj)
  } else {
    // let obj = {
    //   params: config,
    // }
    let obj = {}
    if (type=='json'&&method=='delete') {
      obj.data = config
    } else {
      obj.params = config
      obj.responseType = type||null
    }
    return instance[method.toLocaleLowerCase()](url, obj)
  }
};


export {
  request,
  getPrefixUrl,
  getToken
};
