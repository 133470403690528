import MAP from "./map";

export default {
  address(obj) {
    if(!obj){
      return "无";
    }
    let res = obj.provinceName + obj.cityName;
    res += obj.name ? obj.name : "";
    res += obj.detail ? obj.detail : "";
    return res || '无';
  },
  map(val, name) {
    return MAP[name][val];
  }
}