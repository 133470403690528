import {request} from "./fetch";

// 区域设置
export const areaConfList = param => request('get','/business/tour/area/list',param)  // 列表
export const areaConfs = param => request('get','/business/tour/area/menu',param)  // 不分页列表
export const addAreaConf = param => request('post','/business/tour/area/insert',param)  // 新增
export const addChildAreaConf = param => request('post','/business/tour/area/insert/child',param) // 新增子项
export const editAreaConf = param => request('put','/business/tour/area/insert/child',param) // 修改
export const disableAreaConf = param => request('put','/business/tour/area/disable',param) // 禁用

// 类型设置
export const typeConfList = param => request('get','/business/tour/type/list',param)  // 列表
export const typeConfs = param => request('get','/business/tour/type/menu',param)  // 不分页列表
export const addTypeConf = param => request('post','/business/tour/type/insert',param)  // 新增
export const addChildTypeConf = param => request('post','/business/tour/type/insert/child',param) // 新增子项
export const editTypeConf = param => request('put','/business/tour/type/insert/child',param) // 修改
export const disableTypeConf = param => request('put',`/business/tour/type/disable`,param) // 禁用

// 参数设置
export const getTourConfig = () => request('get','/business/tour/config') // 获取配置
export const editTourConfig = param => request('put','/business/tour/config',param) // 修改配置

// 工单统计
export const workOrderStatistic = param => request('get','business/tour/statistic/list/order',param) // 获取工单统计
export const exportWorkOrderStatistic= param => request('get','business/tour/statistic/list/order/download',param,'blob') // 导出工单统计

// 类型统计
export const typeStatistic = param => request('get','business/tour/statistic/list/type',param) // 获取类型统计
export const exportTypeStatistic= param => request('get','business/tour/statistic/list/type/download',param,'blob') // 导出类型统计

// 部门统计
export const departmentStatistic = param => request('get','business/tour/statistic/list/dept',param) // 获取类型统计
export const exportDepartmentStatistic= param => request('get','business/tour/statistic/list/dept/download',param,'blob') // 导出类型统计

// 工单管理
export const orderSponsorList = param => request('get','business/tour/order/send/list',param) // 获取我发起的工单列表
export const orderAcceptList = param => request('get','business/tour/order/send/handle',param) // 获取我受理的工单列表
export const orderFocustList = param => request('get','business/tour/order/send/attention',param) // 获取我关注的工单列表
export const orderList = param => request('get','business/tour/order/list',param) // 获取所有工单列表
export const focusOrder = param => request('put','business/tour/order/attention',param) // 关注工单
export const revokeOrder = param => request('put','business/tour/order/cancel',param) // 撤销工单
export const orderDetail = param => request('get','business/tour/order/get',param) // 工单详情
export const urgeOrder = param => request('put','business/tour/order/urge',param) // 催单
export const handoverOrder = param => request('put','business/tour/order/handover',param) // 移交
export const solveOrder = param => request('put','business/tour/order/handle',param) // 解决
export const evaluateOrder = param => request('put','business/tour/order/evaluate',param) // 评价
export const postOrder = param => request('post','business/tour/order/insert',param) // 发起工单
export const readOrder = param => request('put','business/tour/order/read',param) // 工单已读

export const orderNodes = param => request('get','business/tour/node/detail/get',param) // 获取工单节点
export const getCommentList = param => request('get','business/tour/node/comment/list',param) // 获取工单节点下的评论信息
export const addNodeComment = param => request('post','business/tour/node/comment',param) // 新增评论

// 看板
export const dashboardOrder = param => request('get','business/tour/statistic/board/order',param) // 获取工单数量统计
export const dashboardDepartment = param => request('get','business/tour/statistic/board/dept',param) // 获取部门统计
export const dashboardType = param => request('get','business/tour/statistic/board/type',param) // 获取类型统计
