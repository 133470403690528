import * as shopapi from './shopModule'
import * as checkapi from './checkModule'
import * as mentapi from './statement'
import * as sysapi from './sysapi'
import * as busapi from './businessModule'
import * as check from './check'
import * as personnel from './personnel'
import * as property from './property'
import * as dynamicForm from './dynamicForm'
import * as tour from './tour'
import * as merchant from './merchant'
import * as paymentType from './paymentType'
export default {
  shopapi,
  checkapi,
  mentapi,
  sysapi,
  busapi,
  check,
  personnel,
  property,
  dynamicForm,
  tour,
  merchant,
  paymentType
}