import {
  request
} from './fetch';

// 上报销售
export const reportSaleExport = config => request('get', '/business/reportSale/export', config, 'blob') // 上报销售报表导出

// 出入许可
export const getAccessPermissionInfo = config => request('get', '/business/accessPermission/report', config) // 查询出入许可报表
export const accessPermissionExport = config => request('get', '/business/accessPermission/export', config, 'blob') // 出入许可报表导出

// 证件管理
export const getCardManagerInfo = config => request('get', '/business/cardManager/report', config) // 查询证件办理报表
export const cardManagerExport = config => request('get', '/business/cardManager/export', config, 'blob') // 证件办理报表导出

// 实名认证
export const getRealnameAuthInfo = config => request('get', '/business/realnameAuth/report', config) // 查询证件办理报表
export const realnameAuthExport = config => request('get', '/business/realnameAuth/export', config, 'blob') // 实名认证报表导出

// 卡券核销
export const getVerifiedRecordList = config => request('get', '/business/verifiedRecord/list', config) // 查询核销列表详情
export const getReturnCardList = config => request('get', '/business/salesReturnRecord/list', config) // 查询退货列表详情
export const exportVerified = config => request('get', '/business/verifiedRecord/export', config, 'blob') // 核销导出
export const exportReturnCard = config => request('get', '/business/salesReturnRecord/export', config, 'blob') // 退款导出

// 预约办卡
export const getCashierApplyList = config => request('get', '/business/cashier/apply/page', config) // 列表
export const exportCashierApply = config => request('get', '/business/cashier/apply/export', config, 'blob') // 导出

// 预约报名 // 活动报名
export const getActivityApplyList = config => request('get', '/business/activity/apply/page', config) // 列表
export const exportActivityApply = config => request('get', '/business/activity/apply/export', config, 'blob') // 导出


// 字段表查询
export const getSysDict = config => request('get', '/system/sysDict/list', config) // 查询字段表

// 核验
export const inspectList = config => request('get','/business/guard/check/list',config) // 核验列表
export const inspectType = () => request('get','/business/guard/check/getTypes') // 核验类型
export const inspectExport = param => request('get','/business/guard/check/exportExcel',param,'blob') // 核验导出


// BPM审批报表
// 审批统计
export const aprStatisticList = param => request('get','/business/report/approve/detail',param) // 列表
export const aprOrders = param => request('get','/business/report/approve/sum',param) // 工单数量
export const aprStatisticExport = param => request('get','/business/report/approve/detail/export',param,'blob') // 导出

// 类型统计
export const aprTypeList = param => request('get','/business/report/approve/type',param) // 列表
export const aprTypeExport = param => request('get','/business/report/approve/type/export',param,'blob') // 导出

// 部门审批统计
export const aprDepartmentList = param => request('get','/business/report/approve/dept',param) // 列表
export const aprDepartmentExport = param => request('get','/business/report/approve/dept/export',param,'blob') // 导出

// 成员审批统计
export const aprMemberList = param => request('get','/business/report/approve/user',param) // 列表
export const aprMemberExport = param => request('get','/business/report/approve/user/export',param,'blob') // 导出

// 成员审批明细统计
export const aprMemberDetailList = param => request('get','/business/report/approve/user/detail',param) // 列表
export const aprMemberDetailExport = param => request('get','/business/report/approve/user/detail/export',param,'blob') // 导出

// 卡券核销
export const couponDetailList = param => request('get','/business/verifiedRecord/list',param) // 明细列表
export const couponDetailExport = param => request('get','/business/verifiedRecord/list/download',param,'blob') // 导出
export const couponSummaryList = param => request('get','/business/verifiedRecord/list/sum',param) // 汇总列表
export const couponTotal = param => request('get','/business/verifiedRecord/list/sum/total',param) // 汇总统计
export const couponSummaryExport = param => request('get','/business/verifiedRecord/list/sum/download',param,'blob') // 导出
