import { Arrays } from "./Arrays.js";
const traverse = new Arrays();

export const flatten = (list) => {
  return list
    .reduce((res, ele) => {
      if (ele.type == "subfield") {
        res.push(...ele.children);
      } else {
        res.push(ele);
      }
      return res;
    }, [])
}

export const jsonTransformer = (json, type = 'post') => {
  let { basic, form, bpm, advanced } = deepClone(json);
  if (type == 'post') {
    // 把当前json构造成需要提交的格式
    // 1. hasBpm值写到basic中
    basic.hasBpm = !bpm.hasBpm;
    delete bpm.hasBpm;
    // 2. 构造出bpmCondition给form
    let bpmCondition = {};
    Object.keys(bpm).forEach(key => {
      if (!/[^0-9]/.test(key)) {
        bpmCondition[key] = bpm[key].bpmCondition
      };
    })
    // console.log('bpmCondition: ', bpmCondition);
    form.bpmCondition = bpmCondition;
    // 3. 把bpm改造成后台需要的数组
    bpm = Object.keys(bpm).filter(e => !/[^0-9]/.test(e)).map(key => {
      return {
        nodeInfo: bpm[key].nodeInfo && bpm[key].nodeInfo.map(e => {
          if (e.users) {
            e.users = e.users.join(",");
          }
          if (e.nodeAuditDefaultUser) {
            if (e.nodeAuditNullType == 1) {
              e.nodeAuditDefaultUser = e.nodeAuditDefaultUser.join(",");
            } else {
              e.nodeAuditDefaultUser = null;
            }
          }
          return e;
        }),
        priorityId: key
      }
    })
    // console.log('bpmList: ', bpm);
    // 4. 改造form对象
    form.tableSearch = traverse.findAll(form.formSetting, (e, i) => e.searchable).map(e => ({ id: e.id, label: e.label }))
    form.tableShow = traverse.findAll(form.formSetting, (e, i) => e.showInList).map(e => ({ id: e.id, label: e.label }))
    // 把form.formSetting改造成对象
    // 这里后台需要的是json，前端中数据结构是数组
    // 考虑到数组仅是对象的一个子集，转为对象更具通用性，这里就暂不修改了
    form.formSetting = form.formSetting.reduce((res, e, i) => {
      res[i] = e;
      return res;
    }, {});
    // 5.当gourpId没填的时候，传一个groupId为0
    basic.groupId = basic.groupId || '0';
  } else if (type == 'get') {
    // 把获取到的json构造成前端需要的格式
    // 以上操作的逆操作
    let formSetting = [];
    if (form.formSetting) {
      for (const [index, val] of Object.entries(form.formSetting)) {
        formSetting[index] = val;
      };
    }
    form.formSetting = formSetting;
    // ---
    let bpmParam = {};
    bpmParam.hasBpm = !basic.hasBpm;
    delete basic.hasBpm;
    if (bpm) {
      bpm.forEach((item) => {
        bpmParam[item.priorityId] = {
          nodeInfo: item.nodeInfo && item.nodeInfo.map(e => {
            let res = { ...e };
            res.users &&
              !Array.isArray(res.users) &&
              [1, 2].includes(+res.nodeType) &&
              (res.users = res.users.split(","));
            !Array.isArray(res.nodeAuditDefaultUser) &&
              res.nodeType == 1 &&
              res.nodeAuditNullType == 1 &&
              (res.nodeAuditDefaultUser = [res.nodeAuditDefaultUser]);
            return res;
          }),
          bpmCondition: form.bpmCondition && form.bpmCondition[item.priorityId]
        }
      })
    }
    bpm = bpmParam;
    basic.groupId = basic.groupId == '0' ? '' : basic.groupId;
  }
  let res = { basic, form, bpm, advanced };
  // console.log(type, res);
  // type == 'get' && console.log([...bpm[1].nodeInfo]);
  return res;
}

export const deepClone = (val) => {
  if (typeof val != 'object' || null == val) {
    return val;
  }
  let res = new val.constructor(); // TODO constructor指向不是特别安全
  for (const [key, value] of Object.entries(val)) {
    res[key] = deepClone(value);
  }
  return res;
}

export const validateIdCard = (id) => {
  // 来自https://segmentfault.com/a/1190000010452673
  // 1 "验证通过!", 0 //校验不通过
  let format =
    /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
  //号码规则校验
  if (!format.test(id)) {
    // return { status: 0, msg: "身份证号码不合规" };
    return false;
  }
  //区位码校验
  //出生年月日校验   前正则限制起始年份为1900;
  let year = id.substr(6, 4), //身份证年
    month = id.substr(10, 2), //身份证月
    date = id.substr(12, 2), //身份证日
    time = Date.parse(month + "-" + date + "-" + year), //身份证日期时间戳date
    now_time = Date.parse(new Date()), //当前时间戳
    dates = new Date(year, month, 0).getDate(); //身份证当月天数
  if (time > now_time || date > dates) {
    // return { status: 0, msg: "出生日期不合规" };
    return false;
  }
  //校验码判断
  let c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
  let b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
  let id_array = id.split("");
  let sum = 0;
  for (let k = 0; k < 17; k++) {
    sum += parseInt(id_array[k]) * parseInt(c[k]);
  }
  if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
    // return { status: 0, msg: "身份证校验码不合规" };
    return false;
  }
  // return { status: 1, msg: "校验通过" };
  return true;
};
// 松散 校验
export const _validateIdCard = (value) => {
  let reg1 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;  // 18位
  let reg2 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/;  // 15位
  if (!(reg1.test(value) || reg2.test(value))) {
    return false
  }
  return true
}