import { request } from './fetch';
function toQueryString(obj) {
  var qstring = "?"
  for (var k in obj) {
    qstring += k + "=" + obj[k] + "&"
  }
  return qstring
}

// 劳动合同审批
export const getContractsList = config => request('get','/business/contract/subject/select',config)  // 获取劳动合同主体下拉框
export const getLaborCtraList = config => request('get', '/business/approval/labor/contract/list',config)  // 查询列表数据
export const delLaborCtraDraft = config => request('delete', '/business/bpm/delete',config,'json')  // 删除草稿数据
export const updateLaborCtraDraft = config => request('put', '/business/approval/labor/contract/update',{...config,hideLoading:true})  // 更新草稿数据
export const insertLaborCtraDraft = config => request('post', '/business/approval/labor/contract/insert',config)  // 新增草稿数据
export const queryLaborCtraDraft = config => request('get', '/business/approval/labor/contract/getUserDraft',config)  // 查询草稿数据
export const submitLaborCtra = config => request('put', '/business/approval/labor/contract/submit',config)  // 提交劳动合同审批申请数据
export const reSubmitLaborCtra = config => request('put', '/business/approval/labor/contract/updateAndActivate',config)  // 更新并且重新发起流程
export const updateFileToCtraData = config => request('put', '/business/approval/labor/contract/updateResources',config)  // 更新文件到审批数据
export const getCtraDataDetail = config => request('get', '/business/approval/labor/contract/getDetail',config)  // 获取详情
export const getCtraHisFlow = config => request('get', '/business/approval/labor/contract/getHistory',config)  // 获取流程历史记录
export const getCtraWaitDetail = config => request('get', '/business/approval/labor/contract/getWaitDetail',config)  // 获取待办详情
export const laborCtrolExport = config => request('get', '/business/approval/labor/contract/list/download',config,'blob')  // 导出

// 劳动合同主体
export const getContracts = config => request('get','/business/contract/subject/list',config)  // 获取劳动合同主体
export const delContract = config => request('delete',`/business/contract/subject/delete/${config}`)  // 删除劳动合同主体
export const addContract = config => request('post','/business/contract/subject/insert',config)  // 添加劳动合同主体
export const editContract = config => request('put','/business/contract/subject/update',config)  // 编辑劳动合同主体

// 提醒设置
export const getEntryCardList = config => request('get','/business/notice/induction/conf/list',config) // 获取入职通知列表
export const delEntryCard = config => request('delete',`/business/notice/induction/conf/delete/${config}`) // 删除入职通知
export const saveEntryCard = config => request('post',`/business/notice/induction/conf/save`,config) // 保存入职通知
export const getDateNoticeList = config => request('get','/business/notice/scheduled/config/list',config) // 获取日期通知设置列表
export const delDateNoticeList= config => request('delete',`/business/notice/scheduled/config/delete/${config}`) // 删除入职通知
export const saveDateNoticeList= config => request('post',`/business/notice/scheduled/config/insert`,config) // 保存入职通知


// 转正申请
export const getPositiveList = config => request('get','/business/approval/full/member/list',config) // 查询列表数据
export const getPositiveDataDetail = config => request('get', '/business/approval/full/member/getDetail',config)  // 获取详情
export const getPositiveHisFlow = config => request('get', '/business/approval/full/member/getHistory',config)  // 获取流程历史记录
export const getPositiveWaitDetail = config => request('get', '/business/approval/full/member/getWaitDetail',config)  // 获取待办详情
export const queryPositiveDraft = config => request('get', '/business/approval/full/member/getUserDraft',config)  // 查询草稿数据
export const insertPositiveDraft = config => request('post', '/business/approval/full/member/insert',config)  // 新增草稿数据
export const updatePositiveDraft = config => request('put', '/business/approval/full/member/update',{...config,hideLoading:true})  // 更新草稿数据
export const submitPositive = config => request('put', '/business/approval/full/member/submit',config)  // 提交劳动合同审批申请数据
export const reSubmitPositive = config => request('put', '/business/approval/full/member/updateAndActivate',config)  // 更新并且重新发起流程
export const positiveExport = config => request('get', '/business/approval/full/member/list/download',config,'blob')  // 导出

// 入职申请管理
export const inductionApprovalList = config => request('post', '/business/inductionApproval/list', config) // 列表查询
export const inductionApprovalSubmit = config => request('post', '/business/inductionApproval/submit', config) // 提交审核
export const inductionApprovalPass = config => request('post', '/business/inductionApproval/pass', config) // 审核通过
export const inductionApprovalRefuse = config => request('post', '/business/inductionApproval/refuse', config) // 审核驳回
export const inductionApprovalRefuseLocal = config => request('post', '/business/inductionApproval/refuseLocal', config) // 审核驳回本级
export const inductionApprovalUpdateAndSubmit = config => request('post', '/business/inductionApproval/updateAndSubmit', config) // 审核驳回本级
export const inductionApprovalInsert = config => request('put', '/business/inductionApproval/hr/submit', config) // HR提交
export const inductionApprovalHrSave = config => request('post', '/business/inductionApproval/hrSave', config) // HR分步保存
export const inductionApprovalEditAndSubmit = config => request('post', '/business/inductionApproval/editAndSubmit', config) // hr编辑并提交审核
export const inductionApprovalUpdateAndActivate = config => request('put', '/business/inductionApproval/updateAndActivate', config) // 更新并且重新发起流程
export const inductionApprovalGetHistory = config => request('get', '/business/inductionApproval/getHistory', config) // 获取流程历史信息
export const inductionApprovalGet = id => request('get', `/business/inductionApproval/process/get?id=${id}`) // 查看详情
export const inductionApprovalWaitGet = taskId => request('get', `/business/inductionApproval/wait/get?taskId=${taskId}`) // 查看待办详情
export const inductionApprovalProcessGet = id => request('get', `/business/inductionApproval/process/get?id=${id}`) // 查看待办详情
export const inductionApprovalRevoke = config => request('post', `/business/inductionApproval/revoke`, config) //撤销
export const inductionApprovalGetUserDraft = config => request('get', `/business/inductionApproval/getUserDraft`, config) //获取该用户的草稿
export const inductionApprovalDraftInsert = config => request('post', `/business/inductionApproval/insert`, config) //新增草稿
export const inductionApprovalDraftUpdate = config => request('put', `/business/inductionApproval/update`, {...config,hideLoading:true}) //更新草稿
export const inductionApprovalInsertRefuseDraft = config => request('post', `/business/inductionApproval/insert/refuse/draft`, config) //新增已驳回状态下的草稿
export const inductionApprovalHrEdit = config => request('post', '/business/inductionApproval/hrEdit', config) // HR完整保存/* 废弃接口 */
export const inductionApprovalExport = config => request('get','/business/inductionApproval/list/download',config,'blob')
// export const inductionApprovalInsert = config => request('post', '/business/inductionApproval/insert', config) // HR新增/* 废弃接口 */
// export const inductionApprovalGet = id => request('get', `/business/inductionApproval/get?id=${id}`) // 查看详情/* 废弃接口 */

// 审核管理-人事流转
export const approvalPersonOperationInsert = config => request('post', `/business/approval/person/operation/insert`, config) // 查看待办详情
export const approvalPersonOperationList = config => request('get', `/business/approval/person/operation/list` + toQueryString(config)) //获取申请列表（分页）
export const approvalPersonOperationGet = id => request('get', `/business/approval/person/operation/process/get?id=${id}`) //获取申请详情
export const approvalPersonOperationWaitGet = taskId => request('get', `/business/approval/person/operation/wait/get?taskId=${taskId}`) //获取待办详情
export const approvalPersonOperationProcessGet = id => request('get', `/business/approval/person/operation/process/get?id=${id}`) //发起的、审核的、抄送的流程详情
export const approvalPersonOperationPass = config => request('put', `/business/approval/person/operation/pass`, config) //审核通过
export const approvalPersonOperationRefuse = config => request('put', `/business/approval/person/operation/refuse`, config) //驳回
export const approvalPersonOperationCancel = config => request('put', `/business/approval/person/operation/cancel`, config) //撤销

export const approvalPersonOperationGetUserDraft = config => request('get','/business/approval/person/operation/getUserDraft',config)  // 获取该用户的草稿
export const approvalPersonOperationDraftInsert = config => request('post','/business/approval/person/operation/draft/insert',config)  // 新增草稿
export const approvalPersonOperationDraftUpdate = config => request('put','/business/approval/person/operation/draft/update',{...config,hideLoading:true})  // 更新草稿
export const approvalPersonOperationSubmit = config => request('put', `/business/approval/person/operation/draft/submit`, config) //提交并发起流程
export const approvalPersonOperationUpdate = config => request('put', `/business/approval/person/operation/updateAndActivate`, config) //更新并且重新发起流程
export const approvalPersonOperationGetHistory = config => request('get', `/business/approval/person/operation/getHistory`, config) //获取流程历史信息
export const approvalPersonExport = config => request('get','/business/approval/person/operation/list/download',config,'blob') // 导出

// 岗位
export const sysPostList = config => request('get', '/business/sysPost/list', config) // 岗位列表

// 部门
export const sysDeptTree = config => request('get', '/sys/sysDept/tree', config) // 部门树查询
export const sysPeopleTree = config => request('get', '/sys/sysDept/people/tree', config) // 部门树（包含人员）查询

// 职级
export const sysJobGradeList = config => request('get', '/business/sys/job/grade/list', config) // 职级列表

// bpm流程管理
export const bpmGetModelValue = config => request('get', '/business/bpm/get/model/value?value=' + config) // 查看流程（模板）

// 员工花名册管理
export const staffRosterList = config => request('post', '/business/staffRoster/list', config) // 列表查询
export const staffRosterInsert = config => request('post', '/business/staffRoster/insert', config) // 新增
export const staffRosterUpdate = config => request('post', '/business/staffRoster/update', config) // 编辑
export const staffRosterGet = config => {
  let query = "?";
  if (config.jobNumber) {
    query += `jobNumber=${config.jobNumber}`
  }
  if (config.id) {
    query += `id=${config.id}`
  }
  return request('get', '/business/staffRoster/get' + query)
} // 详情查询

export const getApprovalType = config => request('get','/business/bpm/get/key',config)  // 获取审批模板
export const getChangeReasons = config => request('get','/business/staff/change/reason/list',config)  // 获取变化原因
export const reset = config => request('put','/business/bpm/reset',config)  // 重置草稿
