export default {
  politicalStatus: {
    // 政治面貌
    0: "群众",
    1: "中共党员",
    2: "共青团员",
  },
  maritalStatus: {
    // 婚姻状况
    0: "已婚",
    1: "未婚",
    2: "离异",
    3: "丧偶",
  },
  learnMethod: {
    // 学习方式
    0: "全日制",
    1: "成人教育",
    2: "网络教育",
  },
  education: {
    // 学历
    0: "小学",
    1: "初中",
    2: "高中（职高、中专）",
    3: "大专（高职）",
    4: "本科",
    5: "研究生",
    6: "博士研究生",
  },
  academicDegree: {
    // 学位
    0: "学士",
    1: "硕士",
    2: "博士",
  },
  sex: {
    // 性别
    M: "男",
    F: "女",
  },
  workNature: {
    // 工作性质
    0: "全职",
    1: "兼职",
    2: "临时工",
  },
  value: {
    // 流程模板
    101: "施工图审批",
    201: "店铺效果图审批",
    301: "平面图审批",
    401: "围挡效果图审批",
    501: "武汉大悦城入职审批",
    502: "长沙大悦城入职审批",
    503: "其他平台区域项目入职审批",
    601: "入职流转流程",
  },
  computerLevel: {
    0: "不懂",
    1: "一般",
    2: "熟练",
  },
  checkStatus: {
    // 审批状态
    0: "未提审",
    1: "已撤销",
    2: "处理中",
    3: "已驳回",
    4: "已通过",
    7: "已终止",
    5: "驳回待修改",
    101: "待入职员工填写",
    102: "提审无审批",
  },
  auditStatus:{
    1: "已撤销",
    2: "处理中",
    3: "已驳回",
    4: "已通过",
    7: "已终止",
  },
  aiosAuditStatus:{
    0: "处理中",
    1: "已通过",
    2: "已驳回",
    3: "已撤销",
    5: "已终止",
  },
  workerStatus:{
    0:"待接单",
    1:"已接单",
    2:"已拒绝",
    3:"待确认",
    4:"维修中",
    5:"已维修",
    6:"完成",
    7:"未完成"
  },
  propertyStatus:{
    1:"待分配",
    2:"待接单",
    3:"待维修",
    4:"维修中",
    5:"已维修",
    6:"完成",
    7:"终止",
    8:"挂起",
  },
  BPMtimeTags: {
    1:'昨日',
    2:'本周',
    3:'本月',
    7:'近7日',
    30:'近30日'
  },
  tourTimeTags: {
    0:'今日',
    7:'近7天',
    15:'近15天',
    30:'近30天',
  },
  workOrderStaStatus:{
    7:'已终止',
    13: '已完成'
  },
  tourTypes:{
    1: '一级类型',
    2: '全部'
  },
  tourStatus:{
    1:'处理中',
    2:'撤销',
    3:'完成待评价',
    4:'完成'
  },
  copounType: {
    1:'代金券',
    2:'折扣券',
    3:'礼品券'
  },
  copounStatus: {
    1:'核销',
    2:'退款'
  },
  ticketStatus: {
    1: '未处理',
    2: '已处理',
    3: '已撤回'
  },
  payChannels: {
    0: 'WEB',
    3: '微信'
  },
  payMethods: {
    WX: '微信',
    ZFB: '支付宝',
    YL: '银联',
    XJ: '现金',
  },
  deviceTypes: {
    Electricity: '电表',
    Water: '水表',
  },
  ticketBtnColor: {
    1: 'danger',
    2: 'primary',
    3: 'info'
  },
  screenStatus: {
    1: '已撤销',
    2: '待审核',
    3: '已驳回',
    4: '已通过'
  },
  managerStatus: {
    0: '店员',
    1: '店长'
  },
  states:{
    1:"在职",
    2:"未激活",
    3:"离职",
    4:"年审",
  },

  getter(name) {
    return Object.keys(this[name]).sort((a,b)=>a-b>0).map(key => {
      return {
        value: key,
        label: this[name][key]
      }
    })
  }
}