import { request } from './fetch';

// 实名认证
export const realnameList = config => request('get', '/business/realnameAuth/list', config) // 查询实名认证列表
export const getRealnameInfo = config => request('get',`/business/realnameAuth/get/${config}`) // 查询实名认证详情
export const insertOrUpdate = config => request('post', '/business/realnameAuth/audit', config) // 查询实名认证审核


// 上报销售
export const reportSaleList = config => request('get', '/business/reportSale/list', config) // 查询上报销售详情列表
export const getReportSaleInfo = config => request('get',`/business/reportSale/get/${config}`) // 查询上报销售详情
export const reportSaleUpdate = config => request('post', '/business/reportSale/audit', config) // 上报销售审核
export const getSaleStatisticsListApi = config => request('get', '/business/reportSale/statistic/list', config) // 获取上报销售统计
export const downloadSaleStatisticsApi = config => request('get', '/business/reportSale/export/statistic', config,'blob') // 下载销售统计

// 出入许可
export const accessPermissionList = config => request('get', '/business/accessPermission/list', config) // 查询出入许可列表
export const accessPermissionInfo = config => request('get',`/business/accessPermission/get/${config}`) // 查询出入许可详情
export const accessPermissionUpdate = config => request('post', '/business/accessPermission/audit', config) // 上报销售审核

// 证件管理
export const getCardManagerList = config => request('get', '/business/cardManager/list', config) // 查询证件管理列表
export const cardManagerInfo = config => request('get',`/business/cardManager/get/${config}`) // 查询证件办理详情
export const cardManagerAudit = config => request('post', 'business/cardManager/audit', config) //申请店长审核

// 店长申请

export const getManagerApplyList = config => request('get', '/business/managerApply/list', config) // 店长申请列表
export const getManagerApplyInfo = config => request('get',`/business/managerApply/get/${config}`) // 店长申请详情
export const managerApplyUpdate = config => request('post', '/business/managerApply/audit', config) //申请店长审核


// 字段表查询
export const getSysDict = config => request('get', '/system/sysDict/list', config) // 查询字段表


// 事务处理 ---我的待办---我的发起---我的审批---我的抄送
export const waitList = data => request('get', '/business/bpm/wait/list', data) // 获取待办事项列表
export const processList = data => request('get', '/business/bpm/process/list', data) // 获取我的发起列表
export const completedList = data => request('get', '/business/bpm/completed/list', data) // 获取我的审批列表
export const sendList = data => request('get', '/business/bpm/send/list', data); // 获取我的抄送列表

export const getTodoDetail = data => request('get','/business/decoration/approval/wait/get',data)  // 获取待办详情数据


// 通用接口
export const rejectMyAppply = data => request('PUT','/business/bpm/refuse',data) // 驳回
export const repealMyApply = config => request('put', '/business/bpm/cancel',config)  // 撤销
export const terminateMyApply = config => request('put', '/business/bpm/end',config)  // 终止
export const passMyAppply = data => request("put",'/business/bpm/pass',data) // 通過
export const readMark = data => request('put','/business/bpm/mark/read',data) //标记已读
export const getNewest = id => request('get',`/business/bpm/get/newest?id=${id}`) //查询历史关联的最新编号
export const getRefuseDraft = id => request('get',`/business/bpm/get/refuse/draft?id=${id}`) //获取已驳回状态下的草稿编号

// 商户投屏
export const getMerchantScreenApi = data => request('get', 'business/screen/shop/approval/list', data) // 查询
export const getMechantScreenDetailApi = data => request('get', 'business/screen/shop/approval', data) // 详情
export const getMechantScreenNodeApi = data => request('get', 'business/screen/shop/approval/detail', data) // 获取节点详情
export const rejectMerchantScreenApi = data => request('put', 'business/screen/shop/approval/refuse', data) // 驳回
export const agreeMerchantScreenApi = data => request('put', 'business/screen/shop/approval/pass', data) // 审核通过

export const getCashierListApi = data => request('get', 'business/cashier/approval/list/operate', data) // 查询
export const detailCashierApi = data => request('get', 'business/cashier/approval', data) // 详情
export const agreeCashierApi = data => request('put', 'business/cashier/approval/pass', data) // 通过
export const rejectCashierApi = data => request('put', 'business/cashier/approval/refuse', data) // 驳回
