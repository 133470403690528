import { request } from './fetch';

// 预约管理
export const getActivityManagerList = config => request('get', '/business/activity/manager/page', config) // 分页列表
export const addActivityManager = config => request('post', '/business/activity/manager', config) // 新增数据
export const putActivityManager = config => request('put', '/business/activity/manager', config) // 修改数据
export const getActivityInfo = config => request('get', `/business/activity/manager/${config}`) // 获取详情
export const stopActivity = config => request('put', `/business/activity/manager/stop/${config}`) // 终止
export const deleteActivity = config => request('delete', `/business/activity/manager/${config}`) // 删除
export const startActivity = config => request('put', `business/activity/manager/start/${config}`) // 开启活动



// 收银员办卡
export const getCashierManagerList = config => request('get', '/business/cashier/manager/page', config) // 分页列表
export const addCashierManager = config => request('post', '/business/cashier/manager', config) // 新增数据
export const putCashierManager = config => request('put', '/business/cashier/manager', config) // 修改数据
export const getCashierInfo = config => request('get', `/business/cashier/manager/${config}`) // 获取详情
export const stopCashier = config => request('post', `/business/cashier/manager/stop/${config}`) // 终止
export const deleteCashier = config => request('delete', `/business/cashier/manager/${config}`) // 删除
export const startCashier = config => request('put',`/business/cashier/manager/start/${config}`) // 开启活动

// 收银员信息
export const getPosUserCashierList = config => request('get', '/business/posUserCashier/page', config) // 分页列表
export const startPosUserCashier = config => request('put', `/business/posUserCashier/start/${config}`) // 启用
export const batchStartPosUserCashier = config => request('put', '/business/posUserCashier/start/batch', config) // 批量启用
export const stopPosUserCashier = config => request('put', `/business/posUserCashier/stop/${config}`) // 暂停
export const batchStopPosUserCashier = config => request('put', 'business/posUserCashier/stop/batch', config) // 批量暂停
export const deletePostUserCashier = config => request('delete', `/business/posUserCashier/${config}`) // 删除
export const batchDelPostUserCashier = config => request('delete', 'business/posUserCashier/batch', config, 'json') // 批量删除

// 通知公告
export const getNoticeList = config => request('get', '/business/shop/push/list', config) // 列表
export const getNoticeDetail = config => request('get', '/business/shop/push', config) // 详情
export const addNotice = config => request('post', '/business/shop/push', config) // 新增
export const getShopList = config => request('get', '/business/shop/floor/market/shop', config) // 列表
export const getFloorList = config => request('get', '/sys/floor/list', config) // 列表
export const getBusinessList = config => request('get', '/sys/business/list', config) // 列表

// 营销信息
export const getQyMsgList = config => request('get', '/business/wx/qy/msg/list', config) // 商场企业群发列表
export const getTagGroupDetail = config => request('get', '/business/shop/member/tag/group/getDetail', config) // 标签群组详情
export const queryGroupMember = config => request('post', '/business/shop/member/tag/group/query', config) // 筛选群组会员
export const qyMsg = config => request('post', '/business/wx/qy/msg', config) // 创建企业群发
export const qyMediaUpload = config => request('post', '/business/wx/qy/media/upload', config) // 上传临时素材（企业微信群发资源）

