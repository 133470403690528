export default {
    SHOP_CACHE(state,newVal){
        state.shopCache = newVal
    },
    CHECK_CACHE(state,newVal){
        state.checkcache.realname = newVal
    },
    REPORT_SALE(state,newVal){
        state.checkcache.reportSale = newVal
    },
    OUT_ALLOW(state,newVal){
        state.checkcache.outAllow = newVal
    },
    PAPER_SHANDLE(state,newVal){
        state.checkcache.paperShandle = newVal
    },
    SET_TITLE(state,value){
        state.content_title = value
    }
}
