import { config } from 'mathjs';
import { request } from './fetch';

// 罚单配置
export const getMerchantTicketLisyApi = config => request('get', '/business/penal/type/list/with/option', config) // 分页列表
export const addMerchantTickApi = config => request('post', '/business/penal/type', config) // 新增
export const updateMerchantTickApi = config => request('put', '/business/penal/type', config) // 修改
export const disableMerchantTickApi = config => request('put', '/business/penal/type/disable', config) // 禁用
export const getPenalTypesApi = config => request('get', '/business/penal/type/list', config) // 获取罚金类型列表

// 罚金项
export const getFineItemListApi = config => request('get', '/business/penal/option/list', config) // 获取罚金项目列表
export const addFineItemApi = config => request('post', '/business/penal/option', config) // 新增罚金项
export const updateFineItemApi = config => request('put', '/business/penal/option', config) // 修改罚金项
export const disableFineItemApi = config => request('put', '/business/penal/option/disable', config) // 禁用罚金项

// 商户罚单
export const getTicketListApi = config => request('get', '/business/penal/shop/list', config) // 罚单列表
export const addTicketApi = config => request('post', '/business/penal/shop', config) // 新增罚单
export const detailTicketApi = config => request('get', 'business/penal/shop', config) // 罚单详情
export const flowDetailTicketApi = config => request('get', '/business/penal/shop/node/detail', config) // 罚单节点流程详情
export const urgeTicketApi = config => request('put', '/business/penal/shop/urge', config) // 催单
export const cancelTickApi = config => request('put', '/business/penal/shop/cancel', config) // 撤销
export const exportTicketApi = config => request('get', '/business/penal/shop/list/download', config, 'blob') // 导出
export const checkTicketApi = config => request('put', '/business/penal/shop/check', config) // 核验

// 水电表
export const getRechargeRecordListApi = config => request('get', '/business/meter/api/recharge/getRechargeRecordList', config) // 充值记录（场馆）
export const getPayLogListApi = config => request('get', '/business/wx/pay/log/list', config) // 支付日志列表
export const payLogNotifyApi = id => request('post', `/business/wx/pay/log/notify?id=${id}`) //重新操作
export const exportRecordApi = config => request('get', '/business/meter/api/recharge/getRechargeRecordList/download', config, 'blob') // 导出
export const getRechargeBillListApi = config => request('get', '/business/meter/api/market/recharge/getBill', config) // 账单汇总（场馆）
export const exportBillApi = config => request('get', '/business/meter/api/market/recharge/getBill/download', config, 'blob') // 下载账单汇总（场馆）报表
export const getCreateNoApi = config => request('get', '/business/meter/yxhj/createNo', config) // 获取充值记录（云溪汇聚）
