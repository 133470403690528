// import { resolve } from 'any-promise'

export default {
  setShopCache({ commit }, targetUser){ //清空 DSarch 下面各项值
    commit('SHOP_CACHE',targetUser)
  },
  setRealname({ commit }, targetUser){
    commit('CHECK_CACHE',targetUser)
  },
  setRreportSale({ commit }, targetUser){
    commit('REPORT_SALE',targetUser)
  },
  setOutAllow({ commit }, targetUser){
    commit('OUT_ALLOW',targetUser)
  },
  setPaperShandle({ commit }, targetUser){
    commit('PAPER_SHANDLE',targetUser)
  },
}
